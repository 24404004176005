import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { 
    column: String,   // data-column-value => .dataset.columnValue
    direction: String // data-column-direction => .dataset.columnDirection
  }

  connect() {
    // console.log("connecting elements, GET /cities => sort_controller.js") // 69 elements?
  }

  initialize() {
    this.defaultOrder = this.element.closest('table').querySelectorAll('span.rent-value')
  }

  sort() {
    const table = this.element.closest('table')
    // console.log(table)

    // pasa la activeColumn.dataset para preguntar .columnValue or .columnDirection
    const activeColumn = this.element.dataset
    this.getSpanValues(activeColumn, table)
  }

  getSpanValues(activeColumn, table) {
    if (activeColumn.columnValue == 'rent') {
      const colValues = table.querySelectorAll('span.rent-value')
      this.setColumnsStates(activeColumn, colValues, table)
    } else if (activeColumn.columnValue == 'buy') {
      const colValues = table.querySelectorAll('span.buy-value')
      this.setColumnsStates(activeColumn, colValues, table)
    } else if (activeColumn.columnValue == 'mortgage') {
      const colValues = table.querySelectorAll('span.mortgage-value')
      this.setColumnsStates(activeColumn, colValues, table)
    }
  }

  setColumnsStates(activeColumn, colValues, table) {
    const columns = {
      rent: table.querySelector(`button[data-column-value='rent']`).dataset,
      buy: table.querySelector(`button[data-column-value='buy']`).dataset,
      mortgage: table.querySelector(`button[data-column-value='mortgage']`).dataset,
    }

    // Iterate over the activeColumn.columnDirection in each click
    if (activeColumn.columnDirection === 'default') {
      activeColumn.columnDirection = 'ascending'
    }
    else if (activeColumn.columnDirection === 'ascending') {
      activeColumn.columnDirection = 'descending'
    }
    else if (activeColumn.columnDirection === 'descending') {
      activeColumn.columnDirection = 'default'
    }
    
    const columnIteration = activeColumn.columnDirection
    this.sortColumn(columnIteration, colValues)

    // Reset other columns' sorting directions
    for (const columnValue in columns) {
      if (activeColumn.columnValue !== columnValue) {
        columns[columnValue].columnDirection = 'default'
      }
    }

    // if want to use .forEach, transform the columns Object into an array of values
    // const columnsArray = Object.values(columns)
    // columnsArray.forEach((col) => {
    //   if (col.columnValue !== activeColumn.columnValue) {
    //     col.columnDirection = 'default'
    //   }
    // })
  }

  sortColumn(columnIteration, colValues) {
    // colValues => NodeList no tiene .sort()
    const nodeListArray = Array.from(colValues)

    if (columnIteration == 'ascending') {
      nodeListArray.sort((a,b) => {
        const aValue = parseInt(a.dataset.value, 10)
        const bValue = parseInt(b.dataset.value, 10)
        return aValue - bValue
      })
    } else if (columnIteration == 'descending') {
      nodeListArray.sort((a,b) => {
        const aValue = parseInt(a.dataset.value, 10)
        const bValue = parseInt(b.dataset.value, 10)
        return bValue - aValue
      })
    } else if (columnIteration == 'default') {
      nodeListArray.length = 0 // Clear the current array
      Array.prototype.push.apply(nodeListArray, this.defaultOrder)
    }
    this.changeIcons()
    this.redrawTable(nodeListArray)
  }

  changeIcons() {
    const columnName = this.element.dataset.columnValue
    const columnDirection = this.element.dataset.columnDirection
    const icons = {
      rent: this.element.closest('table').querySelector(`button[data-column-value='rent']`).children,
      buy: this.element.closest('table').querySelector(`button[data-column-value='buy']`).children,
      mortgage: this.element.closest('table').querySelector(`button[data-column-value='mortgage']`).children
    }

    const resetIcons = () => {
      // for (const key in icons) {}
      Object.keys(icons).forEach((key) => {
        const iconGroup = icons[key]
        iconGroup[0].classList.remove('hidden')
        iconGroup[1].classList.add('hidden')
        iconGroup[2].classList.add('hidden')
      })
    }

    const updateIcons = () => {
      if (columnName in icons) {
        const targetIcons = icons[columnName]
        if (columnDirection === 'ascending') {
          targetIcons[0].classList.add('hidden')
          targetIcons[1].classList.remove('hidden')
          targetIcons[2].classList.add('hidden')
        } else if (columnDirection === 'descending') {
          targetIcons[0].classList.add('hidden')
          targetIcons[1].classList.add('hidden')
          targetIcons[2].classList.remove('hidden')
        } else if (columnDirection === 'default') {
          resetIcons()
        }
      }
    }

    resetIcons() // resetea todas las columns
    updateIcons() // luego updatea la clickeada
  }

  redrawTable(nodeListArray) {
    const table = this.element.closest('table')
    const tbody = table.querySelector('tbody')
    tbody.innerHTML = ''

    nodeListArray.forEach((span) => {
      const row = span.closest('tr')
      tbody.appendChild(row)
    })
  }

} // EOF export default class extends Controller
