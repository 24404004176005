import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="test"
export default class extends Controller {
  connect() {
    // console.log("test_controller.js connected")
    // console.log("Connected to:", this.element)
  }

  click(event) {
    console.log("Clicked element", event.currentTarget)
  }
}
